<template>
  <v-form autocomplete="on" @submit.prevent="signIn">
    <v-text-field
      id="email"
      type="text"
      name="email"
      autocomplete="username"
      v-model="credentials.username"
      :placeholder="$t('general.email_address')"
      :error="$v.credentials.username.$error || signInFailed"
      outlined
    ></v-text-field>

    <v-text-field
      id="password"
      :type="showPassword ? 'text' : 'password'"
      name="password"
      autocomplete="current-password"
      v-model="credentials.password"
      :placeholder="$t('password.title_and_length')"
      :error="signInFailed"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      outlined
      @click:append="showPassword = !showPassword"
    ></v-text-field>

    <v-expand-transition>
      <ErrorMessage v-if="errorMessage !== null">
        {{ errorMessage }}
      </ErrorMessage>
    </v-expand-transition>

    <v-btn type="submit" color="primary" elevation="5" :loading="isLoggingIn" x-large block>
      {{ $t('login.log_in') }}
    </v-btn>
  </v-form>
</template>

<script>
import profileCompletenessHelper from '@/helpers/ProfileCompletenessHelper';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { login } from '@access/services/accessService';
import ErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'LoginForm',
  components: { ErrorMessage },
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      errorMessage: null,
      showPassword: false,
      loginFailed: false,
      loggingIn: false,
    };
  },
  validations: {
    credentials: {
      username: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    isLoggingIn() {
      return this.loggingIn;
    },
    signInFailed() {
      return this.loginFailed;
    },
  },
  created() {
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
    }
  },
  methods: {
    signIn() {
      this.loggingIn = true;
      this.loginFailed = false;

      this.$v.credentials.$touch();
      if (this.$v.credentials.$error) {
        this.errorMessage = this.$t('login.credentials_invalid');
        this.loginFailed = true;
        this.loggingIn = false;

        return;
      }

      login({ credentials: this.credentials })
        .then((resp) => {
          const urlBeforeLogin = this.$route.query.redirect_to;
          const profile = resp.data;

          localStorage.setItem('token', profile?.access_token);
          window.dataLayer.push({
            event: 'login',
            category: 'user',
            action: 'login',
            label: 'successful',
            user_id: profile.email,
          });

          this.$store.commit('resetProfile');

          if (
            urlBeforeLogin &&
            -1 === urlBeforeLogin.indexOf('/login') &&
            0 === urlBeforeLogin.indexOf(window.location.origin)
          ) {
            window.location.href = urlBeforeLogin;
          } else if (profileCompletenessHelper.isTutorialUnfinished(profile)) {
            this.$router.push({
              name: 'tutorial',
            });
          } else if (profile?.active_workspace_id) {
            this.$router.push({
              name: 'dashboard',
              params: {
                workspace_id: profile?.active_workspace_id,
              },
            });
          } else {
            this.$router.push({
              name: 'dashboard',
            });
          }
        })
        .catch((err) => {
          if (400 === err.code) {
            this.errorMessage = this.$t('login.email_uncofirmed');
            this.$notification.error(this.$t('login.email_uncofirmed'));

            return this.$router.push({
              name: 'registerToConfirm',
              params: {
                email: this.user.email,
              },
            });
          }

          this.loginFailed = true;
          this.errorMessage = this.$t('login.credentials_invalid.short');
          this.$notification.error(this.$t('login.credentials_invalid.short'));
        })
        .finally(() => {
          this.loggingIn = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
