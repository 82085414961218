<template>
  <v-card class="mx-auto" elevation="5" width="100%" max-width="38rem" rounded="xl">
    <LanguageToolbar />

    <v-card width="100%" max-width="30rem" class="mx-auto" elevation="0">
      <v-card-title>
        <h1 class="font-weight-bold text-left mb-0">
          {{ $t('access.login') }}
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row align="center" justify="center" class="pb-4">
          <v-col cols="12" class="mt-4">
            <LoginForm />
          </v-col>

          <v-col cols="12" class="pb-1">
            <router-link
              class="font-weight-regular anchor link"
              :to="{ name: 'passwordForgotten' }"
            >
              {{ $t('login.did_you_forget_password') }}
            </router-link>
          </v-col>

          <v-col v-if="canCreateAccount" cols="12" class="pa-0">
            <v-btn class="font-weight-regular anchor px-3" plain text @click="onCreateAccount">
              <span class="text-decoration-underline link">
                {{ $t('login.create_account_link') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import LanguageToolbar from '@access/components/LanguageToolbar';
import LoginForm from '@access/components/LoginForm';
import environment from '@/config/environment';

export default {
  name: 'Login',
  components: {
    LoginForm,
    LanguageToolbar,
  },
  computed: {
    canCreateAccount() {
      return !environment.isRestrictedMode();
    },
  },
  created() {
    localStorage.removeItem('token');
    localStorage.removeItem('workspace_id');
  },
  methods: {
    onCreateAccount() {
      window.dataLayer.push({
        event: 'register',
        category: 'user',
        action: 'register',
        label: 'innitiate',
      });

      this.$router.push({ path: 'register' });
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor::v-deep {
  color: #5b5f66 !important;
  font-size: 15px;

  .v-btn__content {
    opacity: 1 !important;
  }

  .link:hover {
    color: #5f23a0 !important;
  }

  &.link:hover {
    color: #5f23a0 !important;
  }
}
</style>
